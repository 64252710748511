<!--
 * @Descripttion: 
 * @version: 
 * @Author: gaolongzhi
 * @Date: 2020-08-18 14:11:49
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2020-10-16 11:01:08
-->
<template>
  <div class="salaryPage">
    <section class="header">
      <div class="time"> 近期发放 <span>{{ emolument.createDate=="暂未发放"?emolument.createDate:formatTime(emolument.createDate) }}</span>
      </div>
      <el-row>
        <el-col :span="5" :offset="5" class="lg">
          <p class="num">{{ balance }}</p>
          <p class="text">可用余额(元)</p>
        </el-col>
        <el-col :span="3" :offset="item.offset" v-for="(item,index) in dataList" :key="index">
          <p class="text">{{item.text}}</p>
          <p class="num">{{ item.num }}</p>
        </el-col>
        <el-col :span="3" :offset="dataList.length===0?11:0">
          <el-button type="primary" round plain :disabled="(balance-0)===0?true:false" :class="{disabled:(balance-0)===0?true:false}" @click="handleWithdrawals()">提现</el-button>
          <!-- <el-button type="primary" round plain :class="{disabled:emolument.id==null?true:false}" @click="handleRecentlyDetail(emolument.id)">提现</el-button> -->
          <!-- <a href="javascript:void(0);" type="primary" class="question" v-if="emolument.id != null" @click="handleShowQuestion"> 对收入有疑问 <i class="el-icon-question"></i>
          </a> -->
        </el-col>
      </el-row>
    </section>
    <!-- 自定义选项卡 -->
    <router-link to="/salary/income" exact class="tagdef">我的收入</router-link>
    <router-link to="/salary/record" exact class="tagdef">资金记录</router-link>
    <router-view></router-view>
  </div>
</template>

<script>
import empty from "@/components/empty/index.vue";
import api from "@/api/list";
import moment from "moment";
export default {
  name: "salaryList",
  components: {
    empty,
  },
  data() {
    return {
      balance: 0,

      emolument: {
        //need
        id: null,
        amount: 0,
        createDate: "暂未发放",
        amountPayable: 0,
        personalTaxAmount: 0,
      },
        dataList: [],
    };
  },
  // computed: {
  //   resultList() {
  //     this.initOcompanyBalance();
  //     let offsetLength = 0;
  //     let resultArr = [];
  //     if (this.userInfo.dataList.length <= 3) {
  //       offsetLength += (3 - this.userInfo.dataList.length) * 3;
  //     }
  //     this.userInfo.dataList.forEach((value, index) => {
  //       if (index < 3) {
  //         resultArr.push({
  //           text: value.text,
  //           num: value.num,
  //           offset: index === 0 ? offsetLength : 0,
  //         });
  //       }
  //     });
  //     return resultArr;
  //   },
  // },
  created(){
    this.initOcompanyBalance();
  },
  mounted() {
    api.getRecentlySalary().then((res) => {
      //need
      if (res.data) {
        this.emolument = res.data;
        if (this.emolument !== null) {
          this.emolument.amountPayable = this.emolument.amountPayable.toFixed(
            2
          );
          this.emolument.personalTaxAmount = this.emolument.personalTaxAmount.toFixed(
            2
          );
          this.emolument.amount = this.emolument.amount.toFixed(2);
        }
      }
    });
  },
  methods: {
      //初始化可用余额
      initOcompanyBalance(){
          api.getOcompanyBalance().then((res) => {
              if (res.status == "200" && res.data){
                  this.ocompanyAmountList = res.data;
                  let totalBalance = 0;
                  if (res.data != null) {
                      this.ocompanyAmountList.forEach(function (obj) {
                          totalBalance += parseFloat(obj.balance);
                      });
                      this.balance = totalBalance.toFixed(2);
                      this.ocompanyAmountList.forEach((value, index) => {
                          if (index < 3) {
                              this.dataList.push({
                                text: value.ocompanyName,
                                num: value.balance,
                              });
                            }
                          });
                  }else {
                      this.balance = 0;
                  }
              }
          });
      } ,
    formatTime(date) {
      //need
      return moment(date).format("YYYY年MM月DD日");
    },
    handleWithdrawals() {
      //need
      this.$router.push({ name: "withdrawals" });
    },
  },
};
</script>

<style lang="less" scoped>
.salaryPage {
  //need
  padding: 12px 0;
  width: 1210px;
  margin: 0 auto;

  .image_preview {
    position: relative;
    /deep/ .el-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      & > img {
        opacity: 0;
      }
    }
  }
}
section {
  margin-bottom: 12px;
  background: #fff;
  border-radius: 6px;

  p {
    margin: 0;
  }
  &.header {
    //need
    position: relative;
    height: 192px;
    color: #333;
    line-height: 30px;
    text-align: center;
    overflow: hidden;
    padding: 70px 0;
    background: url(../../assets/header_salary.png) left top no-repeat #fff;

    .time {
      //need
      position: absolute;
      left: 0;
      top: 0;
      font-size: 14px;
      color: #fff;
      width: 220px;
      line-height: 42px;
      text-align: center;
      span {
        display: inline-block;
        vertical-align: middle;
        margin-left: 4px;
        font-size: 16px;
        margin-top: -2px;
      }
    }

    .num {
      //need
      color: #999;
      font-size: 16px;
    }

    .text {
      //need
      font-size: 14px;
      color: #666;
    }

    .lg {
      //need
      line-height: 34px;
      margin-top: -6px;
      .num {
        font-size: 36px;
        color: #333;
      }

      .text {
        font-size: 16px;
      }
    }

    .el-button {
      display: block;
      width: 122px;
      background: #fff;
      padding: 9px 24px;
      font-size: 16px;
      margin: 16px auto 3px;
      color: #8493fa;
      border-color: #8493fa;

      &:hover {
        color: #fff;
        background: #8493fa;
      }
    }
  }

  header {
    font-size: 18px;
    line-height: 50px;
    padding-left: 30px;
    font-weight: 600;
    /deep/ .el-form {
      float: right;
      margin: 10px 0;
      width: 80%;
      .el-form-item--mini.el-form-item {
        margin-bottom: 0;
      }
    }
  }

  /deep/ .el-table {
    min-height: 300px;
    .cell {
      text-align: center;
    }

    td,
    th {
      padding: 4px 0;
    }

    thead th {
      background: #8f9aee;
      color: #fff;
    }

    &.el-table--striped .el-table__body tr.el-table__row--striped td {
      background: #eff0f6;
    }
  }
}

.file_box {
  display: block;
  width: 100%;
  height: 722px;
}
/*
 *自定义选项卡
 */
a.tagdef {
  display: inline-block;
  box-sizing: border-box;
  height: 40px;
  width: 50%;
  text-align: center;
  line-height: 40px;
  color: #333;
  background: white;
  font-weight: bold;
  border: 1px solid #e4e7ed;
  &:not(:last-of-type) {
    border-right: 0;
  }
  &.router-link-active {
    color: #8f9aee;
    border-bottom: 0;
  }
}
</style>